<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col cols="auto" class="mr-auto" v-if="$can('export_jamimas_request')">
        <div>
          <b-button variant="success" @click="exportAll"
            >Export All Transaction</b-button
          >
        </div>
      </b-col>
      <b-col cols="auto" class="button-wrapper" v-if="$can('multiple_release')">
        <div style="margin-right: 1rem;">
          <b-button
            v-show="isApprove"
            variant="success"
            @click="approveMultiple('approve')"
          >
            Approve Multiple
          </b-button>
        </div>
        <div>
          <b-button
            v-show="isRetry"
            variant="primary"
            @click="approveMultiple('retry')"
          >
            Retry Multiple
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <b-form-group
          class="mb-3"
          label="Search"
          label-for="search"
          description="Searchable: Name, Email, Invoice Number, Bank"
        >
          <b-input-group>
            <b-form-input
              id="search"
              type="search"
              v-model="filterText"
              placeholder="Name, Email, Invoice Number, Bank"
              @keyup.enter="doFilter"
            >
            </b-form-input>
            <b-input-group-append>
              <b-button variant="secondary" type="button" @click="resetFilter">
                reset
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Search By Status"
            label-for="label-status"
          >
            <b-form-select
              id="label-status"
              :options="statusOption"
              v-model="status"
              @change="doFilterStatus"
            ></b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm>
        <div>
          <b-form-group class="mb-3" label="Date" label-for="dateRange">
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker
                id="dateRange"
                :start="startDate"
                :end="endDate"
                @picker="doDateFilter"
              ></range-picker>
              <b-input-group-append>
                <b-button
                  type="button"
                  variant="secondary"
                  @click="resetDateFilter"
                  >Reset</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable
      ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData"
    >
      <template slot="checkbox-slot" slot-scope="prop">
        <input
          type="checkbox"
          :value="prop.rowData"
          @click="onChangeChecked(prop.rowData)"
          v-model="selected"
          v-show="prop.rowData.can_approve || prop.rowData.can_retry"
        />
      </template>
      <template slot="date-slot" slot-scope="prop">
        <span>{{ prop.rowData.created_at }}</span>
      </template>
      <template slot="user-slot" slot-scope="prop">
        <router-link
          :to="onAction('view-user', prop.rowData, prop.rowIndex)"
          target="_blank"
          >{{ prop.rowData.users.name }} ({{
            prop.rowData.users.email
          }})</router-link
        >
      </template>
      <template slot="invoice-slot" slot-scope="prop">
        <span>{{ prop.rowData.invoice_number }}</span>
      </template>
      <template slot="description-slot" slot-scope="prop">
        <span
          v-if="
            prop.rowData.bank_accounts.owner_name !== '-' ||
              prop.rowData.bank_list.bank_name !== '-' ||
              prop.rowData.bank_accounts.account_number !== '-'
          "
        >
          {{ prop.rowData.bank_accounts.owner_name }} -
          {{ prop.rowData.bank_list.bank_name }} -
          {{ prop.rowData.bank_accounts.account_number }}
        </span>
        <span v-else>Celengan</span>
      </template>
      <template slot="gram-slot" slot-scope="prop">
        <span>{{ prop.rowData.gold_amount }}</span>
      </template>
      <template slot="cashout-slot" slot-scope="prop">
        <span>{{ prop.rowData.amount }}</span>
      </template>
      <template slot="status-slot" slot-scope="prop">
        <span v-if="prop.rowData.status === 0">Sedang Diproses</span>
        <span v-else-if="prop.rowData.status === 1">Berhasil</span>
        <span v-else-if="prop.rowData.status === 2">Ditolak</span>
        <span v-else-if="prop.rowData.status === 3">Gagal</span>
      </template>
      <template slot="actions-slot" slot-scope="prop">
        <div class="action-wrapper">
          <b-button
            variant="warning"
            style="margin-bottom: .5rem;"
            @click="onAction('check-item', prop.rowData)"
            >Check</b-button
          >
          <b-button
            variant="danger"
            v-if="$can('approve_jamimas_request')"
            style="margin-bottom: .5rem;"
            v-show="prop.rowData.status === 3"
            @click="
              onAction(
                'log-response',
                prop.rowData.log_response,
                (logResponse = true)
              )
            "
            >log</b-button
          >
          <b-button
            variant="info"
            v-if="$can('detail_jamimas_request')"
            @click="onAction('detail-item', prop.rowData)"
            style="margin-bottom: .5rem;"
            >Detail</b-button
          >
          <b-button
            variant="success"
            v-if="$can('approve_jamimas_request')"
            style="margin-bottom: .5rem;"
            v-show="prop.rowData.can_approve"
            @click="onAction('approve', prop.rowData)"
            >Approve</b-button
          >
          <b-button
            variant="primary"
            v-if="$can('approve_jamimas_request')"
            style="margin-bottom: .5rem;"
            v-show="prop.rowData.can_retry"
            @click="onAction('retry', prop.rowData)"
            >Retry</b-button
          >
        </div>
      </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
      <vuetable-pagination
        ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
    <b-modal
      size="xl"
      title="Log Response"
      v-model="logResponse"
      @oke="logResponse = false"
    >
      <div style="text-align: center; width: 100%">
        {{ this.errorResponse }}
      </div>
    </b-modal>
    <b-modal
      title="Check Account"
      size="lg"
      v-model="checkModal"
      centered
      hide-footer
    >
      <div v-if="this.isLoadingModal">
        <b-row class="mb-4">
          <b-col>
            <b-skeleton width="210px"></b-skeleton>
            <hr />
            <b-skeleton
              v-for="index in 5"
              :key="index"
              width="250px"
              style="margin: 0 0 10px 40px"
            ></b-skeleton>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <b-skeleton width="180px"></b-skeleton>
            <hr />
            <b-skeleton
              v-for="index in 4"
              :key="index"
              width="230px"
              style="margin: 0 0 10px 40px"
            ></b-skeleton>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <b-skeleton width="160px"></b-skeleton>
            <hr />
            <b-skeleton
              v-for="index in 3"
              :key="index"
              width="270px"
              style="margin: 0 0 10px 40px"
            ></b-skeleton>
            <div style="margin-top: 32px">
              <b-skeleton width="500px"></b-skeleton>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="typeof this.user.user !== 'undefined'">
        <b-row class="mb-4">
          <b-col>
            <h6>User Profile</h6>
            <hr />
            <ul style="font-size: 12pt">
              <li>
                Partner / Organization : {{ this.user.user.organization_name }}
              </li>
              <li>Nama : {{ this.user.user.name }}</li>
              <li>Email : {{ this.user.user.email }}</li>
              <li>No Telepon : {{ this.user.user.phone }}</li>
              <li>Alamat : {{ this.user.user.address }}</li>
            </ul>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <h5>
              Bank Account Details
            </h5>
            <hr />
            <ul style="font-size: 12pt">
              <li>Owner : {{ this.user.bank.account_name }}</li>
              <li>Account Number : {{ this.user.bank.account_number }}</li>
              <li>Bank Name : {{ this.user.bank.name }}</li>
              <li>Branch : {{ this.user.bank.branch }}</li>
            </ul>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <h6>Status Disbursement</h6>
            <hr />
            <ul style="font-size: 12pt">
              <li>
                Status Transaksi : {{ this.user.disbursement.status_text }}
              </li>
              <li>Response Message : {{ this.user.disbursement.message }}</li>
              <li>
                Tanggal Pengecekan : {{ this.user.disbursement.check_date }}
              </li>
            </ul>
            <div>
              <h5 :style="{ color: user.bank.status ? 'green' : 'red' }">
                {{ this.user.bank.message }}
              </h5>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";

let startDate = "";
let endDate = "";
Vue.use(Vuetable);
export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo
  },
  prop: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      status: "",
      selected: [],
      checkModal: false,
      user: [],
      message: "",
      filterText: "",
      isLoading: false,
      isLoadingModal: false,
      isApprove: false,
      isRetry: false,
      logResponse: false,
      errorResponse: "",
      statusOption: [
        { value: "", text: "Choose Status" },
        { value: 0, text: "Sedang Diproses" },
        { value: 1, text: "Berhasil" },
        { value: 2, text: "Ditolak" },
        { value: 3, text: "Gagal" }
      ],
      errors: {
        code: "",
        message: "",
        status: ""
      },
      apiUrl:
        process.env.VUE_APP_SECRET +
        process.env.VUE_APP_CONFIG +
        `pawn-withdraw`,
      HttpOptions: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        }
      },
      sortOrder: [
        {
          field: "gold_pawn_withdraw.created_at",
          sortField: "gold_pawn_withdraw.created_at",
          direction: "desc"
        }
      ],
      moreParams: {},
      fields: [
        {
          name: "checkbox-slot",
          titleClass: "center aligned",
          dataClass: "center aligned",
          title: "",
          width: "5%"
        },
        {
          name: "date-slot",
          sortField: "gold_pawn_withdraw.created_at",
          title: "Request Date"
        },
        {
          name: "user-slot",
          title: "User"
        },
        {
          name: "invoice-slot",
          title: "Invoice No"
        },
        {
          name: "description-slot",
          title: "Tujuan Pencairan Dana"
        },
        {
          name: "gram-slot",
          title: "Gold Amount"
        },
        {
          name: "cashout-slot",
          title: "Cashout"
        },
        {
          name: "status-slot",
          title: "Status"
        },
        {
          name: "actions-slot",
          title: "Actions",
          titleClass: "center aligned",
          dataClass: "center aligned"
        }
      ]
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    selected(newVal, oldVal) {
      let checkRetry = [];
      let checkApprove = [];

      if (this.selected.length > 0) {
        this.selected.map(itm => {
          // Check Approve condition
          if (itm.can_approve) {
            checkApprove.push(true);
          } else {
            checkApprove.push(false);
          }

          // Check Retry condition
          if (itm.can_retry) {
            checkRetry.push(true);
          } else {
            checkRetry.push(false);
          }
        });
      } else {
        checkRetry = [];
        checkApprove = [];
      }

      // Set true button Retry Multiple
      if (checkRetry.length > 0 && !checkRetry.includes(false)) {
        this.isRetry = true;
      } else {
        this.isRetry = false;
      }

      // Set true button Approve Multiple
      if (checkApprove.length > 0 && !checkApprove.includes(false)) {
        this.isApprove = true;
      } else {
        this.isApprove = false;
      }
    },
    largeModal(newVal, oldVal) {
      if (!newVal && oldVal) this.user = [];
    }
  },
  computed: {
    isRetry() {
      const values = this.selected;
      const valuesFilter = values.filter(itm => itm);

      if (valuesFilter.length > 0) return values.every(itm => itm?.can_retry);

      return false;
    },
    isApprove() {
      const values = this.selected;
      const valuesFilter = values.filter(itm => itm);

      if (valuesFilter.length > 0) return values.every(itm => itm?.can_approve);

      return false;
    }
  },
  methods: {
    onChangeChecked(val) {
      const id = val?.id;
      const arr = this.selected.some(itm => itm?.id == id);

      if (!arr) {
        this.selected.push(val);
      } else {
        const indexId = this.selected.findIndex(itm => itm?.id == id);
        if (indexId > -1) this.selected.splice(indexId, 1);
      }
    },
    onAction(action, data) {
      if (action == "view-user") {
        return { name: "Detail End User", params: { id: btoa(data.users.id) } };
      } else if (action == "detail-item") {
        this.$router.push({
          name: "Jamimas Request Detail",
          params: { id: data.id }
        });
      } else if (action == "check-item") {
        this.checkModal = true;
        this.isLoadingModal = true;
        this.$http
          .post(`pawn/check-transaction`, {
            withdraw_id: data.id
          })
          .then(response => {
            this.isLoadingModal = false;
            this.user = response.data.data;
          })
          .catch(error => {
            this.checkModal = false;
            this.isLoading = false;
            this.isLoadingModal = false;
            this.errors.code = error.response.status;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
            if (this.errors.status == 400 || this.errors.status == 404) {
              this.$swal
                .fire("Failed", this.errors.message, "error")
                .then(() => {});
            }
          });
      } else if (action === "log-response") {
        if (this.isJsonString(data)) {
          this.errorResponse = JSON.parse(data);
        } else {
          this.errorResponse = data;
        }
      } else {
        this.isLoading = true;
        this.$http
          .post(
            `pawn-withdraw/${
              action == "approve" ? "approve" : "retry-approve"
            }`,
            {
              hashed_id: data.id
            }
          )
          .then(() => {
            this.isLoading = false;
            this.$swal
              .fire("Success!", "Jaminan Emas Berhasil Diapprove!", "success")
              .then(() => {
                location.reload();
              });
          })
          .catch(error => {
            if (error.response) {
              this.isLoading = false;
              this.errors.code = error.response.status;
              this.errors.message = error.response.data.meta.message;
              this.errors.status = error.response.data.meta.code;
              if (this.errors.status == 400) {
                this.$swal
                  .fire("Failed", this.errors.message, "error")
                  .then(() => {
                    location.reload();
                  });
              } else if (this.errors.status == 404) {
                this.$swal
                  .fire("Failed", this.errors.message, "error")
                  .then(() => {
                    location.reload();
                  });
              }
            }
          });
      }
    },
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return false;
    },
    doFilter() {
      this.$events.$emit("filter-set", this.filterText);
    },
    doFilterStatus() {
      this.$events.$emit("status-set", this.status);
    },
    resetFilter() {
      this.filterText = "";
      this.$events.$emit("filter-reset");
    },
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem("access_token") != null) {
          localStorage.removeItem("access_token");
          this.$swal
            .fire(
              "Your session expired!",
              "Your session has expired. Please login again to access this page!",
              "error"
            )
            .then(() => {
              this.$router.push("/login");
            });
        }
      } else if (this.errors.code == 403) {
        this.$router.push("/403");
      } else if (this.errors.code == 500) {
        this.$router.push("/500");
      }
    },
    resetDateFilter() {
      (this.startDate = ""),
        (this.endDate = ""),
        (this.status = ""),
        this.$events.$emit("date-filter-reset");
    },
    allcap(value) {
      return value.toUpperCase();
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.$refs.paginationInfo.setPaginationData(paginationData);
    },
    onFilterSet(filterText) {
      this.moreParams = {
        filter: filterText
      };
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    onStatusFilterSet() {
      if (this.status === "") {
        this.onFilterReset();
      } else {
        this.moreParams = {
          status: this.status
        };
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      }
    },
    doDateFilter(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
      this.$events.fire("date-set", [this.startDate, this.endDate]);
    },
    onDateSet() {
      this.moreParams = {
        start_from: this.startDate,
        end_to: this.endDate
      };
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    onFilterReset() {
      this.moreParams = {};
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    onDateFilterReset() {
      this.moreParams = {};
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    exportAll() {
      this.isLoading = true;
      if (this.startDate != "" && this.endDate != "") {
        startDate = this.startDate;
        endDate = this.endDate;
      } else {
        endDate = this.$setDate.format("YYYY-MM-DD");
        startDate = this.$setDate.subtract(6, "days").format("YYYY-MM-DD");
      }
      this.$http
        .get(`gold-pawn-withdraw/` + startDate + `/` + endDate)
        .then(result => {
          this.isLoading = false;
          const exportPath = result.data.meta.data;
          window.location.href = exportPath;
        })
        .catch(error => {
          if (error.response) {
            this.isLoading = false;
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        });
    },
    approveMultiple(type) {
      this.$swal
        .fire({
          title: "Apakah kamu yakin?",
          text: `Transaksi yang sudah di proses tidak dapat dibatalkan. Apakah anda yakin ingin memproses ${this.selected.length} transaksi tersebut ?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.value) {
            let arrId = this.selected.map(itm => {
              return itm.id;
            });
            this.isLoading = true;
            this.$http
              .post(
                `pawn-withdraw/${
                  type == "retry" ? "multiple-retry" : "multiple-approve"
                }`,
                {
                  hashed_id: arrId
                }
              )
              .then(() => {
                this.isLoading = false;
                this.$swal
                  .fire(
                    "Success!",
                    "Jaminan Emas Berhasil Diapprove",
                    "success"
                  )
                  .then(() => {
                    location.reload();
                  });
              })
              .catch(error => {
                this.isLoading = false;
                this.errors.code = error.response.status;
                this.errors.message = error.response.data.meta.message;
                this.errors.status = error.response.data.meta.code;
                if (this.errors.status == 400) {
                  this.$swal
                    .fire("Failed", this.errors.message, "error")
                    .then(() => {
                      location.reload();
                    });
                } else if (this.errors.status == 404) {
                  this.$swal
                    .fire("Failed", this.errors.message, "error")
                    .then(() => {
                      location.reload();
                    });
                }
              });
          }
        });
    }
  },
  mounted() {
    this.$events.$on("filter-set", eventData => this.onFilterSet(eventData));
    this.$events.$on("date-set", eventData => this.onDateSet(eventData));
    this.$events.$on("status-set", eventData =>
      this.onStatusFilterSet(eventData)
    );
    this.$events.$on("filter-reset", () => this.onFilterReset());
    this.$events.$on("date-filter-reset", () => this.onDateFilterReset());
  }
};
</script>

<style>
.loading {
  position: fixed;
  z-index: 999;
  height: 2rem;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.button-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  width: 320px;
}

.action-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss"></style>
