<template>
  <div classname="animated">
    <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i> Data Table History Jaminan Emas Transaction
      </b-card-header>
      <b-card-body></b-card-body>
      <transaction></transaction>
    </b-card>
  </div>
</template>

<script>
import transaction from './Table.vue'
export default {
  name: 'jamimas-transaction',
  components: {
    transaction
  }
}
</script>
<style>
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
</style>
